<template>
  <div>
    <div>
      <div></div>
      <b-row>
        <b-col cols="12" md="2">
          <b-form-group>
            <h5>User number</h5>
            <b-form-input v-model="userNumber" type="text" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group>
            <h5>Range create at date</h5>
            <flat-pickr v-model="rangeDateCreateAt" class="form-control" :config="{ mode: 'range' }" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group>
            <h5>Range sent at date</h5>
            <flat-pickr v-model="rangeDateSentAt" class="form-control" :config="{ mode: 'range' }" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <h5>Type</h5>
          <v-select v-model="selectedType" label="title" :options="optionsTypeMessage" />
        </b-col>
        <b-col cols="12" md="1" class="mb-1 ml-1" style="margin-top: 1.7rem !important">
          <button class="btn btn-primary float-right" @click="getSmsListByFilter('')">
            Filter
          </button>
        </b-col>
      </b-row>
      <div class="circle"></div>
      <div style="overflow-x: scroll; min-height: 500px" v-if="totalItems > 0">
        <table small caption-top responsive style="font-size: small" id="table" class="table b-table">
          <thead head-variant="dark">
            <b-tr>
              <th>Sms id</th>
              <th>User Number</th>
              <th>Username</th>
              <th>Type</th>
              <th>From</th>
              <th style="min-width: 400px;">Message</th>
              <th>Datetime</th>
              <th>Seen</th>
              <th>Collab</th>
              <th>Replied</th>
              <th style="min-width: 390px;"> Status</th>
            </b-tr>
          </thead>
          <tbody>
            <tr v-for="item in listItems " :key="item.id">
              <td :class="getClassNoReaded(item)">{{ item.sms_id }}</td>
              <td :class="getClassNoReaded(item)">
                <router-link v-if="item.self_pay || item.driver" :to="{
                  name: 'reservations', query: { search: item.self_pay ? item.self_pay.phone_number : (item.driver ? item.driver.phone_number : '') }
                }">
                  {{ item.user_number }}
                </router-link>
                <template v-if="item.self_pay == null && item.driver == null">
                  {{ item.user_number }}
                </template>
              </td>
              <td :class="getClassNoReaded(item)">
                <router-link v-if="item.self_pay || item.driver" :to="{
                  name: 'reservations', query: { search: item.self_pay ? item.self_pay.phone_number : (item.driver ? item.driver.phone_number : '') }
                }">
                  {{ getUserName(item) }}
                </router-link>
                <template v-if="item.self_pay == null && item.driver == null">
                  {{ getUserName(item) }}
                </template>
              </td>
              <td :class="getClassNoReaded(item)">{{ getTypeUser(item) }}</td>
              <td :class="getClassFrom(item)">
                {{ item.is_out == 1 ? "From amera" : "From outsite" }}
              </td>
              <td :class="getClassNoReaded(item)">{{ item.message }}</td>
              <td :class="getClassNoReaded(item)">{{ getDateSms(item) }}</td>
              <td :class="getClassNoReaded(item)">{{ getWasReaded(item) }}</td>
              <td :class="getClassNoReaded(item)">{{ getNameWhoReaded(item) }}</td>
              <td :class="getClassNoReaded(item)">{{ getDateWasReaded(item) }}</td>
              <td :class="getClassNoReaded(item)">

                <button style="width: 105px;" @click="setSeletedItem(item)" v-b-modal.modal-center class="btn-primary">
                  Send
                  Sms</button>
                <button style="width: 105px;margin-left: 10px;"
                  v-if="item.was_readed != 1 && isFromOutSide(item) == true" @click="changeWasReadedSms(item.id, 1)"
                  class="btn-success">Set as read</button>

                <button v-if="item.sent_confirmed != 1 && isFromAmera(item)" style="width: 105px;margin-left: 10px;"
                  @click="changeAsComfirmed(item.id)" class=" btn-warning">
                  Message Sent
                </button>
              </td>
            </tr>
          </tbody>
          <tbody>
            <b-tr>
              <td colspan="15" variant="secondary" class="text-right">
                Total Rows: <b>{{ totalItems }}</b>
              </td>
            </b-tr>
          </tbody>
        </table>
      </div>
      <div class="pagination_block">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.first_page_url)">
                &laquo;
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.prev_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                Previous
              </button>
            </li>
            <li class="page-item" v-if="current_page - 2 > 0">
              <button type="button" class="page-link" @click="
                getItems(pagination.path + '?page=' + (current_page - 2))
                ">
                {{ current_page - 2 }}
              </button>
            </li>
            <li class="page-item" v-if="pagination.prev_page_url">
              <button type="button" class="page-link" @click="getItems(pagination.prev_page_url)">
                {{ current_page - 1 }}
              </button>
            </li>
            <li class="page-item">
              <button type="button" class="page-link" :class="{ current: pagination.current_page == current_page }">
                {{ current_page }}
              </button>
            </li>
            <li class="page-item" v-if="pagination.next_page_url">
              <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                {{ current_page + 1 }}
              </button>
            </li>
            <li class="page-item" v-if="current_page + 2 <= last_page">
              <button type="button" class="page-link" @click="
                getItems(pagination.path + '?page=' + (current_page + 2))
                ">
                {{ current_page + 2 }}
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.next_page_url)">
                Next
              </button>
            </li>
            <li class="page-item" :class="{ disabled: pagination.next_page_url == null }">
              <button type="button" class="page-link" @click="getItems(pagination.last_page_url)">
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <b-modal id="modal-center" centered hide-footer>
        <div class="d-block text-center">
          <h3>Write the message to {{ getUserNumber(selectedItem) }}</h3>
          <b-col>
            <b-form-group>
              <b-form-input v-model="smsText" />
            </b-form-group>
          </b-col>
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-button block @click="sendSms()">Send</b-button>
          </b-col>
          <b-col>
            <b-button block @click="$bvModal.hide('modal-center')">Close</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTfoot,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInput,
} from "bootstrap-vue";
import { selectedRow } from "@core/utils/cssStyle";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { getMessageError } from "@core/utils/utils";
import {
  convertToMMDDYYYYHHmm,
  convertToMMDDYYYY,
  convertToHHMM,
} from "@core/utils/dates";
// import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from "@core/components/infoClients/UserListAddNew";
import moment from "moment";
export default {
  components: {
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
    BTbody,
    BTfoot,
    BFormGroup,
    flatPickr,
    vSelect,
    BFormInput,
    moment,
  },
  data() {
    return {
      BFormInput: "",
      userNumber: "",
      listItems: [],
      perPage: 10,
      currentPage: 1,
      totalItems: 0,
      pageOptions: [3, 5, 10],
      pagination: {},
      current_page: 1,
      last_page: 0,
      selectedStatus: null,
      rangeDateCreateAt: null,
      rangeDateSentAt: null,
      selectedItem: null,
      listIncomingMessages: [],
      listOutcomingMessages: [],
      smsText: "",
      selectedType: null,
      optionsTypeMessage: [
        { value: 1, title: "From Amera" },
        { value: 0, title: "From outside" },
      ],
      dataGetItFromApi: 0,
      currentUrl: '',
    };
  },
  watch: {
    "$route.params.user_number"() {
      this.$swal({
        title: "Loading data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.loadInformation();
    }
  },
  methods: {
    convertDateHHMM(data) {
      return convertToMMDDYYYYHHmm(data);
    },
    convertDate(data) {
      return convertToMMDDYYYY(data);
    },
    convertTime(data) {
      return convertToHHMM(data);
    },
    getItems(url) {
      this.getSmsListByFilter(url);
    },
    getUserNumber(item) {
      let res = "";

      try {
        if (item != null) {
          res = item.user_number;
        }
      } catch (err) { }

      return res;
    },
    async updateSmsLogList(isOut) {
      let url = "admin/panel/booking/update_smslog_from_api";
      let endTime = moment().format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();
      formData.append("endTime", endTime);
      formData.append("isOut", isOut);

      this.$swal({
        title: "Updating sms log please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      await this.$http
        .post(url, formData)
        .then((response) => {
          this.dataGetItFromApi = this.dataGetItFromApi + 1;
          let tem = this.dataGetItFromApi;
          if (this.dataGetItFromApi == 2) {
            this.$swal.close();
            this.dataGetItFromApi = 0;
            //this.getSmsListByFilter("");
          }
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async getSmsList(url) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      if (url.length === 0) {
        url = "admin/panel/booking/all_list";
      }

      await this.$http
        .get(url)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
          this.$swal.close();
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getSmsListByFilter(url) {
      this.$swal({
        title: "Please, wait receiving sms...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.setValuesFilter();
      let data = this.getValuesParameters();

      if (url == null) {
        url = `admin/panel/booking/get_sms_log_byFilter`;
      }

      if (url.length === 0) {
        url = `admin/panel/booking/get_sms_log_byFilter`;
      }

      this.currentUrl = url;

      this.$http
        .post(url, data)
        .then((response) => {
          this.listItems = response.data.data.data;
          this.totalItems = response.data.data.total;
          this.current_page = response.data.data.current_page;
          this.last_page = response.data.data.last_page;
          this.pagination = response.data.data;
          this.$swal.close();
        })
        .catch((res) => {
          this.$swal({
            title: getMessageError(res),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getValuesParameters() {
      let data = {};
      let dateStartCreateAt = "";
      let dateEndCreateAt = "";
      let dateStartSentAt = "";
      let dateEndSentAt = "";


      //Dates variables filter create at
      if (this.rangeDateCreateAt !== null) {
        dateStartCreateAt = this.rangeDateCreateAt.split("to")[0];
        dateEndCreateAt = this.rangeDateCreateAt.split("to")[1];
      }

      //Dates variables filter sent at
      if (this.rangeDateSentAt !== null) {
        dateStartSentAt = this.rangeDateSentAt.split("to")[0];
        dateEndSentAt = this.rangeDateSentAt.split("to")[1];
      }

      data = {
        userNumber: this.userNumber,
        is_out: this.selectedType == null ? null : (this.selectedType.value == 0 ? false : true),
        dateStartCreateAt: dateStartCreateAt,
        dateEndCreateAt: dateEndCreateAt,
        dateStartSentAt: dateStartSentAt,
        dateEndSentAt: dateEndSentAt,
      };

      return data;
    },
    setSeletedItem(item) {
      this.selectedItem = item;
    },
    sendSms() {
      let formData = new FormData();
      formData.append("userNumber", this.selectedItem.user_number);
      formData.append("text", this.smsText);

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      this.$http
        .post("admin/panel/booking/send_sms_text", formData)
        .then((response) => {
          this.$swal.close();
          this.$bvModal.hide("modal-center");
          this.smsText = "";
          this.$swal({
            title: "Message sent successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getSmsListByFilter(this.currentUrl);
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    async loadInformation() {
      await this.updateSmsLogList(true);
      await this.updateSmsLogList(false);

      if (this.$route.params.user_number) {
        this.userNumber = this.$route.params.user_number;
      } else {
        this.getCurrentValuesFilter();
      }
      if (this.currentUrl == '' || this.currentUrl == null) {
        this.getSmsListByFilter('');
      } else {
        this.getSmsListByFilter(this.currentUrl);
      }

    },
    getUserName(item) {
      let res = "";
      try {
        if (item.self_pay) {
          res = `${item.self_pay.name} ${item.self_pay.lastname}`

        }
        if (item.driver) {
          res = `${item.driver.name} ${item.driver.lastname}`

        }
      } catch (error) {

      }
      return res;

    },
    getTypeUser(item) {
      let res = "";

      try {
        if (item.driver_id) {
          res = `Driver ${item.driver.driver_type}`;
        }
        if (item.client_id) {
          res = "Client";
        }
      } catch (error) {
      }

      return res;
    },
    changeWasReadedSms(id, wasReaded) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("was_readed", wasReaded);

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let url = "admin/panel/set_sms_as_readed";

      if (wasReaded == 0) {
        url = "admin/panel/set_sms_as_not_readed";
      }

      this.$http
        .post(url, formData)
        .then((response) => {
          this.$swal.close();
          this.getSmsListByFilter(this.currentUrl);
          this.$root.$emit('EventGetSmsList');
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    changeAsComfirmed(id) {
      let formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let url = "admin/panel/set_sms_as_confirmed";

      this.$http
        .post(url, formData)
        .then((response) => {
          this.$swal.close();
          this.getSmsListByFilter(this.currentUrl);
          this.$root.$emit('EventGetSmsList');
        })
        .catch((res) => {
          let message = getMessageError(res);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    getNameWhoReaded(item) {
      let res = "";
      try {
        if (item.who_readed_the_sms) {
          res = item.who_readed_the_sms.name
        }
      } catch (error) {

      }
      return res;
    },
    getClassFrom(item) {
      return this.isFromOutSide(item) == true ? "from-outside" : "from-amera";
    },
    getClassNoReaded(item) {
      let res = "";
      if (this.isFromOutSide(item) == true) {
        res = item.was_readed != 1 ? "no-readed" : "";
      }
      return res;
    },
    getDateSms(item) {
      let res = item.sent_at;
      try {
        if (item.sent_at == null || item.sent_at == "") {
          res = item.created_at;
        }
        res = this.convertDateHHMM(res);
      } catch (error) {
      }

      return res;
    },
    setValuesFilter() {
      sessionStorage.setItem("sms_log_list", JSON.stringify(this.userNumber));
    },
    getCurrentValuesFilter() {
      this.userNumber = JSON.parse(sessionStorage.getItem("sms_log_list"));
    },
    getWasReaded(item) {
      let res = "";
      let wasReaded = item.was_readed;
      if (this.isFromOutSide(item) == false) {
        res = 'Yes';
      } else {
        if (wasReaded === 1 || wasReaded === "1") {
          res = 'Yes';
        } else {
          res = 'No';
        }
      }
      return res;
    },
    getDateWasReaded(item) {
      let res = "";
      try {
        if (this.isFromOutSide(item) == false) {
          res = item.created_at;
        } else {
          res = item.date_was_readed;
        }
        res = this.convertToMMDDYYYYHHmm(res);
      } catch (error) {

      }

      return res;
    },
    isFromOutSide(item) {
      let res = false;
      if (item.is_out == 1 ? false : true) {
        res = true;
      }
      return res;
    },
    isFromAmera(item) {
      let res = false;
      if (item.is_out === 1) {
        res = true;
      }
      return res;
    }
  },
  mounted() {
    this.loadInformation();
  },
  updated() {
    selectedRow();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.urlPagina {
  text-decoration: none;
  color: #7367f0;
}

.urlPagina:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.list-group-item:hover {
  background: linear-gradient(118deg,
      #7367f0,
      rgba(115, 103, 240, 0.7)) !important;
  color: #fff !important;
  cursor: pointer;
}

.urlPagina::before {
  background-color: currentColor !important;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.box {
  box-shadow: 0px 14px 20px 0px rgba(143, 143, 143, 0.2) !important;
}

.my-class {
  background: #7367f0;
}

.green_timer {
  background: green;
  color: white;
  padding: 3px;
  border-radius: 8px;
}

.current {
  background: #7367f0;
  color: white;
}

.from-outside {
  background: #838383;
  color: white;
}

.from-amera {
  background: #4f67ca;
  color: white;
}



tr {
  cursor: pointer;
  padding: 0;
}

th {
  background: #4b4b4b !important;
  color: white !important;
  text-transform: uppercase;
}

td {
  padding: 0;
}

.table td,
.table th {
  padding: 3px;
  font-size: x-small;
  min-width: 120px;
  border: solid 1px;
  border-color: #303033;
  background: #fdfdfd;
  color: #303033;
}

.from-outside {
  background-color: #e8e8f6 !important;
}

.from-amera {
  background-color: #789aec !important;
  color: white !important;
}

.no-readed {
  background-color: #fbedd2 !important;
}
</style>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>